import React from 'react'
import "./About.css"
import knowmore from "../../../Assets/know more.jpg"

const About = () => {
  return (
    <div>
        <div className="aboutpara">
             <div className="paragraph">
              <h5>About Us</h5>
              <h1>Welcome to Pixalive Labs </h1>
              <p>Pixalive Corporate Training, managed by Pixalive Labs, a division of Pixalive Research and Development, empowers candidates to upgrade their skills and career prospects. They focus on enhancing aptitude, communication, and interpersonal abilities, preparing students for the job market.  <br /> <br />
             Pixalive Labs offers various technology programs to equip individuals and teams with skills for success in the ever-changing business landscape. <br /> <br />
             Their 'Employee Development Program' also helps organizational employees excel in their tech stacks. We invite you to 'Train Today and be Ready for Tomorrow.” <br /> <br />
             </p>
             </div>
             <div className="imagecontainer">
              <div className="knowledge">
                <h5>Knowledge is Divine</h5>
                
              </div>
              <img src={knowmore} alt="" />
             </div>
        </div>
    </div>
  )
}

export default About