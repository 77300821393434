import React from "react";
import "./Uapdates.css";
import mongodb from "../../../Assets/mongo-db-offices-london-2.jpg"
import react from "../../../Assets/blog-thumb-h3-2.jpg"
import Flutter from "../../../Assets/flutter_io.jpg"
const Upadates = () => {
  return (
    <div>
      <div className="uapdate">
        <p className="text-center"><i class="bi bi-dash-lg"></i>LATEST UPDATES & TRENDS <i class="bi bi-dash-lg"></i></p>
        <h2 className="text-center">Latest Trends in
Full Stack Development</h2>
       <div className='magoDB_all'>
          <div className="Magodbs-all">
            <div className='ManogoDB1'>
            <img src={mongodb} alt=""/>
            <div className='MangoDB_year1'>
                <h6>MongoDB</h6>
                <span>9 Oct 2023</span>
                </div>
              <p className="paraAlldiv">MongoDB Announced New Generative AI Features for Developers</p>
            </div>
            <div className='ManogoDB1  ' >
            <img src={react} alt=""/>
 
              <div className='MangoDB_year1'>
                <h6>React JS</h6>
                <span>03 May 2023</span>
 
              </div>
              <p  className="paraAlldiv">React JS Emerges A Clear Favorite Among World Leaders In Web Development</p>
            </div>
          </div>
          <div className=' Fluter'>
          <img src={Flutter} alt=""/>
          <div className='MangoDB_year1 fluter_year'>
                <h6>React JS</h6>
                <span>03 May 2023</span>
 
              </div>
              <p  className="paraAlldiv paraReact">React JS Emerges A Clear Favorite Among World Leaders In Web Development</p>
 
          </div>
          </div>
         
       
      </div>
    </div>
  );
};
 
export default Upadates;