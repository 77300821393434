import React from "react";
import "./TrainingTechStack.css";
import mernlog1 from "../../../Assets/mean-logo.png"
import mernlogo2 from "../../../Assets/mern-logo2.png"
import mernlogo3 from "../../../Assets/nmdf-logo3.png"
import mernlogo4 from "../../../Assets/mern-logo4.png"
import mernlogo5 from "../../../Assets/react-logo5.png"
import mernlogo6 from "../../../Assets/node-logo6.png"

const TrainingTechStack = () => {

const cardsDetails = [
    {
         id:1,
        image:mernlog1,
        title: "MERN Full Stack Development",
        discription:"The Program is designed to provide an understanding of how the website and webapp functions.",

    },
    {
        id:2,
        image:mernlogo2,
        title:"MEAN Full Stack Development",
        discription:"The Program is designed to provide an understanding of how the website and webapp functions",

    },
    {
        id:3,
        image:mernlogo3,
        title:"Flutter with MongoDB NodeJS",
        discription:"The program is designed to provide an understanding of how the mobile app functions.",

    },
    {
        id:4,
        image:mernlogo4,
        title:"Flutter",
        discription:"The program is designed to provide an understanding of how to build the mobile app functions in iOS & Android.",
    },
    {
        id:5,
        image:mernlogo5,
        title:"React Js",
        discription:"The Program is designed to provide an understanding of how the website and web app functions.",
    },
    {
        id:6,
        image:mernlogo6,
        title:"MongoDB with Node Js",
        discription:"MongoDB and Node.js are used together in web development to create scalable and efficient server-side applications.",
    }
]




  return (
    <div className="trainingtech_container">
      <div className="trainingtech_wrapper">
        <div className="trainingtech_title">
          <div className="line"></div>
          <div className="title">TRAINING TECH STACK</div>
          <div className="line"></div>
        </div>
        <div className="heading">
          <h2>We are giving training in the</h2>
          <h2> following Tech Stac</h2>
        </div>
      </div>
      <div className="trainingtech_cards_container">
       {
        cardsDetails.map((cardData) => (
            <div className="card_wrapper">
                <div key={cardData.id} className="card_image">
                       <img src={cardData.image} alt="" />
                </div>
                <div className="tech_name">
                    <h4>{cardData.title}</h4>
                </div>
                <div className="tech_discription">
                    <p>{cardData.discription}</p>
                </div>
                <div className="arrow_icon">
                <i class="bi bi-arrow-right"></i>
                </div>
            </div>
    ))
       }
          
     </div>
    </div>
  );
};

export default TrainingTechStack;
