import React from "react";
import office1 from "../../../Assets/office2.jpg";
import office3 from "../../../Assets/office.jpg";
import office2 from "../../../Assets/office1.jpg";
import { Link } from "react-router-dom";

const TrainProgram = () => {
  return (
    <div>
      <div className="Training_Program">
        <div className="Training_Title">
          <h1>Pixalive Corporate Training Program</h1>
          <p>
            The Pixalive Corporate Training Program is a career-defining
            opportunity for candidates to acquire <br />
            proficiency in both corporate work culture and programming
            languages, along with technical skills. Our <br /> program primarily
            focuses on 100% of practical training. <br />
            <br /> Our agenda involves engaging candidates/students in real-time
            projects to provide a practical learning <br /> experience. The
            curriculum is specifically designed to address these aspects.
          </p>
        </div>

        <div className="Card_collection">
          <div className="Training_Cards">
            <h4>FullStack Developer Program</h4>

            <div className="Bussiness_cards">
              <div className="Card_Top">
                <img src={office2} alt="" />
                <h5>
                 <div> Program Deposit:</div>
                  <br /> <span>₹59,999 +GST</span>
                </h5>
              </div>
              <div className="Courses_1  text-center">Courses:</div>

              <div className="Project_Lead">
                <div className="Project_in kavin ">
                  <h6>
                    Mobile Application: <br />
                    <span>Flutter</span>
                  </h6>
                </div>
                {/* <div className="Project_out project_in1">
                  <h6>
                    Web Application: <br />
                    <span>React</span>
                  </h6>
                </div> */}
                {/* <div className="Project_out">
                  <h6>
                    Backend: <br />
                    <span>Node.JS,MongoDB </span>
                  </h6>
                </div> */}
              </div>
              <div className="Project_Lead">
                <div className="Project_in">
                  <h6>
                    Domain: <br />
                    <span>Mobile Application Development</span>
                  </h6>
                </div>
                <div className="Project_out">
                  <h6>
                    Projects: <br />
                    <span>Real-time projects</span>
                  </h6>
                </div>
              </div>
              <div className="Qualification">
                <h6>Education Qualification:</h6>
                <p>STEM, BE/BTech, MTech, BSc, MSc, BCA/MCA & Any Degree</p>
              </div>
              <div className="Apply_Button">
                <Link to="/applynow">
                  {" "}
                  <button>APPLY NOW</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="Training_Cards">
            <h4>Frontend Developer Program</h4>

            <div className="Bussiness_cards">
              <div className="Card_Top">
                <img src={office1} alt="" />
                <h5>
                 <div>Program Deposit:</div> 
                  <br /> <span>₹59,999 +GST</span>
                </h5>
              </div>
              <div className="Courses_1  text-center">Courses:</div>

              <div className="Project_Lead">
                {/* <div className="Project_in ">
                  <h6>
                    Mobile Application: <br />
                    <span>Flutter</span>
                  </h6>
                </div> */}
                <div className="Project_out project_in1 kavin">
                  <h6>
                    Web Application: <br />
                    <span>React</span>
                  </h6>
                </div>
                {/* <div className="Project_out">
                  <h6>
                    Backend: <br />
                    <span>Node.JS,MongoDB </span>
                  </h6>
                </div> */}
              </div>
              <div className="Project_Lead">
                <div className="Project_in">
                  <h6>
                    Domain: <br />
                    <span>Web Application Development</span>
                  </h6>
                </div>
                <div className="Project_out">
                  <h6>
                    Projects: <br />
                    <span>Real-time projects</span>
                  </h6>
                </div>
              </div>
              <div className="Qualification">
                <h6>Education Qualification:</h6>
                <p>STEM, BE/BTech, MTech, BSc, MSc, BCA/MCA & Any Degree</p>
              </div>
              <div className="Apply_Button">
                <Link to="/applynow">
                  {" "}
                  <button>APPLY NOW</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="Training_Cards">
            <h4>Backend Developer Program</h4>

            <div className="Bussiness_cards">
              <div className="Card_Top">
                <img src={office1} alt="" />
                <h5>
                <div>  Program Deposit:</div>
                  <br /> <span>₹59,999 +GST</span>
                </h5>
              </div>
              <div className="Courses_1  text-center">Courses:</div>

              <div className="Project_Lead">
                {/* <div className="Project_in ">
                  <h6>
                    Mobile Application: <br />
                    <span>Flutter</span>
                  </h6>
                </div> */}
                {/* <div className="Project_out project_in1">
                  <h6>
                    Web Application: <br />
                    <span>React</span>
                  </h6>
                </div> */}
                <div className="Project_out kavin">
                  <h6>
                    Backend: <br />
                    <span>Node.JS,MongoDB </span>
                  </h6>
                </div>
              </div>
              <div className="Project_Lead">
                <div className="Project_in">
                  <h6>
                    Domain: <br />
                    <span>Web Application Development</span>
                  </h6>
                </div>
                <div className="Project_out">
                  <h6>
                    Projects: <br />
                    <span>Real-time projects</span>
                  </h6>
                </div>
              </div>
              <div className="Qualification">
                <h6>Education Qualification:</h6>
                <p>STEM, BE/BTech, MTech, BSc, MSc, BCA/MCA & Any Degree</p>
              </div>
              <div className="Apply_Button">
                <Link to="/applynow">
                  {" "}
                  <button>APPLY NOW</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="Empty_Note container mt-5">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="Empty_title">
                <h4>
                  <i class="bi bi-dash-lg"></i>Note:
                </h4>
                <div className="Empty_para">
                  <p>
                    During the program students must bring their own personal
                    laptop for the training purposes.
                  </p>
                </div>
              </div>
              <div className="Pixalive_pts">
                <div className="Points_title">
                  <h3>Significance of Pixalive Corporate Training Program</h3>
                </div>
                <div className="points_Subtitle">
                  <p>
                    {" "}
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>{" "}
                    Corporate training & live project work experience from the
                    reputable company.
                  </p>
                  <p>
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>
                    Learn from the industrial experts.
                  </p>
                  <p>
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>{" "}
                    Dedicated, personalized & focused job placement training.
                  </p>
                  <p>
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>{" "}
                    Dedicated HR team for placement support
                  </p>
                   {/* <p>
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>{" "}
                    Cancel any time before the first 15 days.
                  </p>  */}
                  <p>
                    <span>
                      <i class="bi bi-check-circle"></i>
                    </span>{" "}
                    Pixalive Corporate training certificate stands tall &
                    outperform other institutional certificates in the IT
                    sector.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-5 ">
              <div className="office_image mt-5">
                <img src={office3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainProgram;
