import React,{useEffect} from 'react'
import './Career.css'
import offfice from '../../../Assets/office.jpg';
import mic from '../../../Assets/CareerImages/mic.png';
import arrow from '../../../Assets/CareerImages/arrow.png';
import team from '../../../Assets/CareerImages/team.png';
import Aos from 'aos';
import "aos/dist/aos.css";
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import carrers  from '../../../Assets/careers.jpg'
 
 
 
 
const Career = () => {
    useEffect(() => {
        Aos.init({ duration: 3000 });
      }, []);
    const OurworkingData=[
        {
            id:1,
            image:mic,
            openings:'07 Openings',
            heading:'Academics – Instructor, Trainer',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
        {
            id:2,
            image:mic,
            openings:'03 Openings',
            heading:'Flutter Trainer',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
        {
            id:3,
            image:arrow,
            openings:'05  Openings',
            heading:'MERN Stack Trainer',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
        {
            id:4,
            image:team,
            openings:'02 Openings',
            heading:'UI/UX Trainer',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
         {
            id:5,
            image:mic,
            openings:'01 Openings',
            heading:'Business Development Associates',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
        {
            id:6,
            image:arrow,
            openings:'02 Openings',
            heading:'Testing Trainer',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
       
        {
            id:7,
            image:team,
            openings:'04 Openings',
            heading:'Human Resources',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
       
        {
            id:8,
            image:arrow,
            openings:'05 Openings',
            heading:'Customer Support',
            description:'Bring teaching expertise to building powerful learning journeys for students',
            button:'VIEW JOBS',
           
        },
 
    ]
  return (
 
    <div>
        <Header/>
      <div className='career-container'>
        <div className='career-images'>
       
            <img src={offfice} alt=''/>
            <div className='home-careers'>
            <h2>Careers</h2>
                <div className='careers-head'>
                    <p>Home</p><i class="bi bi-slash-lg"></i>
                    <p className='care'>Careers</p>
                </div>
            </div>
           
       
     
       
        </div>
       
 
         <div className='working-cultrue'>
            <div className='paragraph-culture 'data-aos='fade-left' >
                <h1>Working Culture</h1>
                <h5>Embrace a big future with us!</h5>
                <p >The working culture of an office is like the way people act and behave while they're working together. A good working culture means people talk to each other, help each other, and feel good about their jobs. Here are some important things in a good working culture:</p>
                <br/>
                <p>The working culture of an office refers to the collective behaviours, values, and norms that shape how employees interact, collaborate, and conduct themselves in the workplace. A positive working culture is essential for employee well-being, job satisfaction, and overall productivity. Here are some key aspects of office working culture:</p>
            </div>
            <div className='working-images ' data-aos='fade-left'>
                <img src= {carrers} alt=''/>
            </div>
         </div>
         <div className='openings-cards'>
         <div className='openings-headings' data-aos='fade-up'>
            <h1 >Current Openings</h1>
         
           
            <p>Putting people first begins the moment we meet. Tell us about your skills and aspirations. Explore job opportunities that speak to your journey.</p>
            </div>
            <div className='form-openingsall'>
 
            {OurworkingData.map((teamData) => (
        <div className='inform-card' data-aos='fade-up'>
            <div className='image-opeinings'>
 
                <p>{teamData.openings}</p>
                </div>
                <img src={teamData.image} alt=''/>
                <h4>{teamData.heading}</h4>
                <p className='paraall'>{teamData.description}</p>
                <button>{teamData.button}</button>
            </div>
            ))}
                        </div>
 
        </div>
      </div>
      <Footer/>
    </div>
   
  )
}
 
export default Career
 