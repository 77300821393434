import React from 'react'
import { Link } from 'react-router-dom'

const Upcoming1 = () => {
  return (
    <div>
    <div className="Offline_classes">
        <div className="Upcoming_title">
            <h1>Upcoming Batches</h1>
            <p>We conduct offline classes, and updates for it are regularly posted. Keep an eye on this section.</p>
        </div>
      <div className="Three_Cards">
      
            <div className="Cards_venue bluecard">
                <h4>OFFLINE - BENGALURU</h4>
                <h3>Job Guaranteed Program</h3>
                <span className='Class_time'>Batch 1</span><br/>
                <span className='Class_days'>MON to FRI</span>
                <h5><i class="bi bi-calendar"></i>July 15</h5>
                <h5><i class="bi bi-clock-fill"></i>09:00 am - 06:00 pm</h5>
             <Link to = '/applynow'>   <button>REQUEST A CALL BACK</button></Link>
            </div>
            

        
     
      
             <div className="Cards_venue pinkcard">
                <h4>OFFLINE - BENGALURU</h4>
                <h3>Job Assistance Program</h3>
                <span className='Class_time'>Batch 2</span><br/>
                <span className='Class_days'>MON to FRI</span>
                <h5><i class="bi bi-calendar"></i>July 15</h5>
                <h5><i class="bi bi-clock-fill"></i>09:00 am - 06:00 pm</h5>
                <button>REQUEST A CALL BACK</button>
            </div>
             

        
      
        
            {/* <div className="Cards_venue orangecard">
                <h4>OFFLINE - BENGALURU</h4>
                <h3>Individual Program</h3>
                <span className='Class_time'>Batch 2</span><br/>
                <span className='Class_days'>MON to FRI</span>
                <h5><i class="bi bi-calendar"></i>June 17</h5>
                <h5><i class="bi bi-clock-fill"></i>09:00 am - 06:00 pm</h5>
              <Link to='/applynow'>  <button>REQUEST A CALL BACK</button></Link>
            </div> */}
            

        
      </div>
    </div>
</div>
  )
}

export default Upcoming1