import React from 'react'
import google from '../../../Assets/google (1).png'
import amazon from '../../../Assets/shopping.png'
import netflix from '../../../Assets/netflix.png'
import ibm from '../../../Assets/ibm.png'
import FedEx from '../../../Assets/fedex.png'
import Walmart from '../../../Assets/walmart log.png'


import './Logo.css'

const Logo = () => {
    const BrandLogo=[{
        id:1,
        name:"google",
        image:google,
      },
      {
        id:2,
        name:"facebook",
        image:amazon,
      },
      {
        id:3,
        name:"google",
        image:netflix,
      },
      {
        id:4,
        name:"google",
        image:ibm,
      },
      {
        id:5,
        name:"google",
        image:FedEx,
      },
      {
        id:6,
        name:"walmart",
        image:Walmart,
      },
     
     
    ]
const infinitelogos = [...BrandLogo,...BrandLogo]
    
  return (
    <div>
         <div className='all_logos'>
         <h1 className=' text-center'>Our Alumni at Work</h1>
      <div className='carousel-logos'>
         <div className='Logo-Detials'>
          {infinitelogos.map((Brands)=>(
            <div className='Logo_Inform' key={Brands.id}>
            <img src={Brands.image} alt={Brands.name}/>
            </div>
        ))}
      </div>
      </div>
      </div>
      
 

    </div>
  )
}

export default Logo
