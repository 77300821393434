import React from 'react'
import Carreer from './Batches/Carreer'
import Corporate from './Corporate/TrainProgram'
import Upcoming from '../../../src/Pages/Programs/Upcoming/Upcoming'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import CareerServices from './Tabs/CareerServices'

const programs = () => {
  return (
    <div>
        <Header/>
        <CareerServices/>
         {/* <Corporate/>
        <Carreer/>
        <Upcoming/>  */}
        <Footer/>
    </div>
  )
}

export default programs