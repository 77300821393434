import React from "react";
import "./Banner.css";
import profile from "../../images_pixalivegroup/image__1_-removebg-preview-removebg-preview.png";
import wat from "../../images_pixalivegroup/whatsapp-svgrepo-com 1.svg";

const Banner = () => {
  const defaultMessage = "Hello, I am interested in partnering with you.";

  return (
    <div>
      <div className="container  back_container rounded-3">
        <div className="container p-3">
          <div className="row  banner_container">
            <div className="col-lg-2 "></div>
            <div className="col-lg-5 col-sm-12  banner_text">
              <h3 className="text-white fw-bold  text-sizeheading mt-4">
                Expand Your Business
              </h3>
              <h4 className="mt-5 text-white mediatest ">
                We have numerous openings in software development and the IT
                sector. We are seeking for business partnerships with vendors
                and consultants to assist us in hiring candidates for our
                Bangalore location.
              </h4>

              <h4 className="mt-4 text-white">Focusing Location For Vendors</h4>
              <h3 className="text-greencolor">
                Bangalore &nbsp; Hyderabad &nbsp; Chennai
              </h3>
              <a
                href={`https://wa.me/7760871042?text=${encodeURIComponent(
                  defaultMessage
                )}`}
                target="_blank"
                className="buttoncolorcss1 shake-animation mt-4"
              >
                <img src={wat} alt="" className="" />
                &nbsp;Contact Us
              </a>
            </div>

            <div className="col-4 mx-4 helloprofile mt-3">
              <img src={profile} alt="" className="img-fluid  shadow-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
