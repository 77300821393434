import React, { useState } from "react";
import "./CareerServices.css";
import { SlCalender } from "react-icons/sl";
import Carrers1 from "../Tab1/Carrers1";
import Tab1 from "../Tab1/Tab1";
import Upcoming1 from "../Tab1/Upcoming1";
import Carreer2 from "../Tab2/Carreer2";
import Corporate2 from "../Tab2/Corporate2";
import Upcoming2 from "../Tab2/Upcoming2";
import Carreer3 from "../Tab3/Carreer3";
import Corporate3 from "../Tab3/Corporate3";
import Upcoming3 from "../Tab3/Upcoming3";
import Corporate4 from "../Tab4/Corporate4";
import Carrers4 from "../Tab4/Carrers4";
import Upcoming4 from "../Tab4/Upcoming4";
import offfice from "../../../Assets/office.jpg";
import meeting from "../../../Assets/meeting.jpg"
import Logo from "../Logo/Logo";

const CareerServices = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className="career-images">
        <img src={offfice} alt="" />
        <div className="home-careers">
          <h2>Programs</h2>
          <div className="careers-head">
            <p>Home</p>
            <i class="bi bi-slash-lg"></i>
            <p className="care">Programs</p>
          </div>
        </div>
      </div>
      <div className="career_services">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-11">
              <p className="h4">
                Career Services By <b>Pixalive</b>
              </p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <img
                src={meeting}
                alt="Career Services"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7">
              <div className="tabs-container">
                <div className="tabs ">
                  <div
                    className={`tab ${activeTab === 0 ? "active" : ""}`}
                    onClick={() => handleTabClick(0)}
                  >
                    Job Guarantee
                  </div>
                  <div
                    className={`tab ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Job Assistance
                  </div>
                  <div
                    className={`tab ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleTabClick(2)}
                  >
                      Training
                   
                  </div>
                  <div
                    className={`tab ${activeTab === 3 ? "active" : ""}`}
                    onClick={() => handleTabClick(3)}
                  >
                     Interview Preparations
                  
                  </div>
                </div>
                <div className="tab-content">
                  {activeTab === 0 && (
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 py-4">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/guaranteed.png"
                                  alt="Placement Assistance"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>Placement Assurance</h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" /> Upon
                                    movement to the Placement Pool
                                  </p>
                                </div>
                              
                                <p>
                                  Placement opportunities are provided once the
                                  learner is moved to the placement pool upon
                                  clearing Placement Readiness Test (PRT)
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/job_portal.png"
                                  alt="Job Portal"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>
                                    Exclusive access to Pixalive Job portal
                                  </h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    After 80% of the course completion
                                  </p>
                                </div>
                                <p>
                                  Exclusive access to our dedicated job portal
                                  and apply for jobs. More than 400 hiring
                                  partners’ including top start-ups and product
                                  companies hiring our learners. Mentored
                                  support on job search and relevant jobs for
                                  your career
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 py-4">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/interview.png"
                                  alt="Mock Interview Preparation"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>Placement Assistance</h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    After 80% of the course completion.
                                  </p>
                                </div>
                                <p>
                                  Placement opportunities are provided once the
                                  learner is moved to the placement pool upon
                                  clearing Placement Readiness Test (PRT)
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/expert.png"
                                  alt="Career Mentoring"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>
                                    {" "}
                                    Exclusive access to Pixalive Job portal{" "}
                                  </h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    After 90% of the course completion
                                  </p>
                                </div>
                                <p>
                                  Exclusive access to our dedicated job portal
                                  and apply for jobs. More than 400 hiring
                                  partners’ including top start-ups and product
                                  companies hiring our learners. Mentored
                                  support on job search and relevant jobs for
                                  your career
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 py-4">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/resume-1.png"
                                  alt="Career Oriented Sessions"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>Training Assistance </h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    Throughout the course
                                  </p>
                                </div>
                                <p>
                                  Students will go through several mock
                                  interviews conducted by technical experts who
                                  will then offer tips and constructive feedback
                                  for reference and improvement.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/linkedin-1.png"
                                  alt="Resume & LinkedIn Profile Building"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5> 1 on 1 Career Mentoring Sessions </h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    After 70% of course completion
                                  </p>
                                </div>
                                <p>
                                  Attend one-on-one sessions with career mentors
                                  on how to develop the required skills and
                                  attitude to secure a dream job based on a
                                  learners’ educational background, experience,
                                  and future career aspirations.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 py-4">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/resume-1.png"
                                  alt="Career Oriented Sessions"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between  flex-wrap">
                                  <h5>Placement Assistance</h5>
                                  <p className="fw-bold ">
                                    <SlCalender className="text-primary" />{" "}
                                    Throughout the course
                                  </p>
                                </div>
                                <p>
                                  Placement opportunities are provided once the
                                  learner is moved to the placement pool upon
                                  clearing Placement Readiness Test (PRT).
                                  Exclusive access to our dedicated job portal
                                  and apply for jobs. More than 400 hiring
                                  partners’ including top start-ups and product
                                  companies hiring our learners. Mentored
                                  support on job search and relevant jobs for
                                  your career
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src="https://intellipaat.com/course-image/2020/08/linkedin-1.png"
                                  alt="Resume & LinkedIn Profile Building"
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between flex-wrap">
                                  <h5>Career Oriented Sessions </h5>
                                  <p className="fw-bold">
                                    <SlCalender className="text-primary" />{" "}
                                    After 70% of course completion
                                  </p>
                                </div>
                                <p>
                                  Live interactive sessions with an industry
                                  expert to gain knowledge and experience on how
                                  to build skills that are expected by hiring
                                  managers. These will be guided sessions that
                                  will help you stay on track with your
                                  upskilling objective.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <Logo />
          {activeTab === 0 && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <Tab1 />
                  <Carrers1 />

                  <Upcoming1 />
                </div>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg-12">
                  <Corporate2 />
                  <Carreer2 />
                  <Upcoming2 />
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <Corporate3 />
                  <Carreer3 />
                  <Upcoming3 />
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <Corporate4 />
                  <Carrers4 />
                  <Upcoming4 />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerServices;
