import React from 'react'
import "./WeAre.css"
import { BsDashLg } from "react-icons/bs";
import ourmission  from "../../../Assets/our mission.jpg"
import who from "../../../Assets/who-thumb2.jpg"
 
const WeAre = () => {
  return (
    <div>
        <div className="tarning">
            <div className="tanings">
 
            <div className="vission">
               <h6><BsDashLg />WHO WE ARE<BsDashLg /></h6>
               <h3 >The corporate Training platform provides an opportunity for passionate individuals</h3>
            </div>
            <div className="platforms ">
    <div className="platforms">
        <div className="platforms_all">
            <img src={ourmission} alt="" />
            <div className="Our_vission_contentall mt-3">
                <h3>Our Mission</h3>
                <p>We focus on corporate training and skill development, providing a range of technology programs that help people get better at their jobs and prepare for shifts in the business world.</p>
            </div>
        </div>
        <div className="  ourvissionoals ">
            <div className="ourvission ">
                <h3>Our Vision</h3>
                <p>To provide quality training & internship for the desired candidates in the real-time projects for their career growth.</p>
            </div>
            <div className="ourgolas ">
                <h3>Goals</h3>
                <p>Mainly focuses & trains the candidates to learn about the application of technical skills to the real-time projects of the company.</p>
            </div>
            <div className="banner ">
                <img src={who} alt="" />
            </div>
        </div>
    </div>
</div>
 
            </div>
 
        </div>
    </div>
  )
}
 
export default WeAre