import React from 'react'
import contact from "../../../Assets/Contact-Banner.jpg"
import "./Gudance.css"
import office from '../../../Assets/office.jpg';
 
import { Link } from 'react-router-dom'
 
 
const Gudance = () => {
  return (
    <div>
        <div className="guidance">
 
            <div className="guid">
            <img src={office} alt="guidence" />
            <div className='gudidnsceall'>
                <h6  className='text-center'><i class="bi bi-dash-lg"></i>GUIDANCE<i class="bi bi-dash-lg"></i></h6>
                <h1 className='text-center'>Get guidance about our program</h1>
         
               <div className='allbutons text-center' ><Link to='/contact'> <button className='allbutons'>CONTACT US<i class="bi bi-arrow-right"></i>  </button></Link>
</div>
               </div>
 
 
            </div>
        </div>
    </div>
  )
}
 
export default Gudance