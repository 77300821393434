import React from "react";
import performance from "../../../Assets/performance.png";
import traning from "../../../Assets/business.png";
import weekdays from "../../../Assets/schedule.png";
import certificate from "../../../Assets/certified.png";
import profile from "../../../Assets/profile.png";
import realtime from "../../../Assets/augmented-reality.png";
import interview from "../../../Assets/business (1).png";
import Team from "../../../Assets/team.png";
import scholarship from "../../../Assets/discount.png";
import intrew from "../../../Assets/business (1).png";

const Carreer = () => {
   
      const carreerDetails = [
        {
          id: 1,
          Image: traning,
          content: "Industrial Expert Session ",
        },
        {
          id: 2,
          Image: certificate,
          content: "Corporate Certificate",
        },
        {
          id: 3,
          Image: weekdays,
          content: "Days Training",
        },
        {
          id: 4,
          Image: interview,
          content: "Interview Preparation",
        },
        {
          id: 5,
          Image: profile,
          content: "Profile Building Session",
        },
        {
          id: 6,
          Image: Team,
          content: "Promotes Teamwork ",
        },
        {
          id: 7,
          Image:scholarship,
          content: "Scholarship Discount ",
        },
        {
          id: 8,
          Image: intrew,
          content: "Interview Preparation ",
        },
        {
          id: 9,
          Image: realtime,
          content: "Real Time Projects",
        },
        {
          id: 10,
          Image: performance,
          content: "100 plus mock interview ",
        },
      ];
      return (
        <div>
          <div className="Highlights_Card">
            <div className="Card_Heading">
              <h2>Key Highlights</h2>
            </div>
            <div className="Carreer_Card_Data">
              {carreerDetails.map((carreerData) => (
                <div key={carreerData.id} className="Carreer_Cards">
                  <div className="Carreer_Image">
                    <img src={carreerData.Image} alt="" />
                  </div>
                  <div className="Career_Content">
                    <h4>{carreerData.content}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

export default Carreer;
