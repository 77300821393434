import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Programs from "./Pages/Programs/programs";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Career from "./Pages/Carrer/Career/Career";
import Apply from "./Pages/Apply/Apply";
import Contact from "./Pages/Contact/Contact";
import Home_1 from "./component_pixalivegroup_campagion/Home/Home";

function App() {
  window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/faviconimg"
            element={
              <div>
                <img src="/pixlogo.ico" alt="" />
              </div>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/program" element={<Programs />} />
          <Route path="/carrers" element={<Career />} />
          <Route path="applynow" element={<Apply />} />
          <Route path="contact" element={<Contact />} />
          <Route path="vendors-call-out" element={<Home_1 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
