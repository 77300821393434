import React from "react";
import "./Footer.css";
import logo from "../../Assets/Labs-logo (2) 3.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="footer_wrapper">
        <div className="about_pixalive">
          <img src={logo} alt="" />
          <p>
            Pixalive Corporate Training Program is organized and conducted by
            Pixalive Labs one of the divisions of Pixalive Groups for the
            candidates to upgrade their skills & enhance their careers which
            helps the companies to hire them easily for their operations.
          </p>
          <div className="social">
            <h5>Social</h5>
            <div className="icons">
              <a href="https://www.facebook.com/profile.php?id=61559897502572">
                <i class="bi bi-facebook"></i>
              </a>
              <a href="https://www.linkedin.com/company/31128662/admin/feed/posts/">
                <i class="bi bi-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/watch?v=RuypjhNQ0MI">
                {" "}
                <i class="bi bi-youtube"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="company">
          <h3>Company</h3>

          <Link to="/program" style={{ textDecoration: "none" }}>
            <p>Programs</p>
          </Link>
          <Link to="/carrers" style={{ textDecoration: "none" }}>
            <p>Career</p>
          </Link>
          <Link to="/applynow" style={{ textDecoration: "none" }}>
            <p>Apply Now</p>
          </Link>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <p>Our Services</p>
          </Link>
        </div>
        <div className="pixalive_divisons">
          <h3>Pixalive Divisions</h3>

          <a
            href="https://pixaliveplatforms.com/"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <p>Pixalive Platforms</p>
          </a>
        
          <a
            href="https://pixaliveservices.com/"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <p> Pixalive Services</p>
          </a>
          <a
            href="https://pixaliveventures.com/"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <p>Pixalive Ventures</p>
          </a>
        
        </div>
      </div>{" "}
      <hr />
      <div className="copyrights_container">
        <p>© Copyright 2024 Pixalive Labs. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
