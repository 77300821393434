import React, { useState } from "react";
import { toast } from "react-toastify";
import "./Apply.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { ApplyUs } from "../../api/apply";
import { uploadFile } from "../../Utils/FileUpload";

const Apply = () => {
  const initialStateInputs = {
    name: "",
    email: "",
    mobileNumber: "",
    referralMobileNumber: "",
    referralName: "",
    resume: "",
    location: "",
    message: "",
  };

  const initialStateErrors = {
    name: { required: false },
    email: { required: false },
    mobileNumber: { required: false },
    resume: { required: false },
    location: { required: false },
    message: { required: false },
  };

  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    if (submitted) {
      setErrors({ ...errors, [event.target.name]: { required: false } });
    }
  };

  const handleValidation = (data) => {
    let newErrors = { ...initialStateErrors };

    if (data.name === "") {
      newErrors.name.required = true;
    }
    if (data.email === "") {
      newErrors.email.required = true;
    }
    if (data.mobileNumber === "") {
      newErrors.mobileNumber.required = true;
    }
    if (data.resume === "") {
      newErrors.resume.required = true;
    }
    if (data.location === "") {
      newErrors.location.required = true;
    }
    if (data.message === "") {
      newErrors.message.required = true;
    }

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);
    const allInputsValid = Object.values(newError).every(
      (error) => !error.required
    );
    if (allInputsValid) {
      ApplyUs(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          event.target.reset();
          setInputs(initialStateInputs);
          setErrors(initialStateErrors);
          setSubmitted(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleFileInputs = (event) => {
    const file = event.target.files[0];
    const folder = "category/image/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const resume = res?.Location;
          setInputs({ ...inputs, resume: resume });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <Header />
      <div className="career-images"></div>
      <div className="form_titles">
        <div className="title_top">
          <h2>Enroll Today; Shine Tomorrow and Beyond</h2>
          <h5 className="mt-3">
            Corporate Training in IT programs helps candidates achieve greater
            success and makes them industry-ready
          </h5>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="container mt-4">
            <div className="row">
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="name" className="label-bold">
                    Name <span className="red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    name="name"
                    onChange={handleInputs}
                  />
                  {errors.name.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="email" className="label-bold">
                    Email ID <span className="red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Mail Id"
                    name="email"
                    onChange={handleInputs}
                  />
                  {errors.email.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="mobileNumber" className="label-bold">
                    Mobile Number <span className="red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Mobile Number"
                    name="mobileNumber"
                    onChange={handleInputs}
                  />
                  {errors.mobileNumber.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="program" className="label-bold">
                    Program <span className="red">*</span>{" "}
                  </label>
                  <select
                    name="program"
                    className="form-select"
                    onChange={handleInputs}
                  >
                    <option value="">Select Program</option>
                    <option value="MERN Full Stack Development">
                      MERN Full Stack Development
                    </option>
                    <option value="Flutter with MongoDB Full Stack">
                      Flutter with MongoDB Full Stack
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="resume" className="label-bold">
                    Resume <span className="red">*</span>{" "}
                  </label>
                  <br />
                  <input
                    type="file"
                    className="form-control pt-3"
                    placeholder="Resume"
                    name="resume"
                    onChange={handleFileInputs}
                  />
                  {/* <img src='' alt=""/> */}

                  {errors.resume.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="col-4"></div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="referralName" className="label-bold">
                    Referral Name{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Referral Name"
                    name="referralName"
                    onChange={handleInputs}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="referralMobileNumber" className="label-bold">
                    Referral Number{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Referral number"
                    name="referralMobileNumber"
                    onChange={handleInputs}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 col-md-6">
                <div className="">
                  <label htmlFor="location" className="label-bold">
                    Location <span className="red">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    name="location"
                    onChange={handleInputs}
                  />
                  {errors.location.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <label htmlFor="message" className="label-bold">
                    Message <span className="red">*</span>{" "}
                  </label>
                  <br />
                  <textarea
                    rows={5}
                    type="text"
                    className="form-control"
                    placeholder="Message"
                    name="message"
                    onChange={handleInputs}
                  />
                  {errors.message.required && (
                    <span className="text-danger form-text">
                      This field is required.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="btns_apply">
            <button className="form_buttonss" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Apply;
