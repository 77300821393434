
import CryptoJS from "crypto-js";
 
 
export const saveToken =  (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem('emailId', CryptoJS.AES.encrypt((data?.emailId),'pixclickForms').toString())
 
  localStorage.setItem('consumerId', CryptoJS.AES.encrypt((data?.consumerId),'pixclickForms').toString())
};
 
export const getToken = () => {
  return localStorage.getItem("token");
};
 
export const getConsumerId = () => {
  const consumerId = localStorage.getItem('consumerId');
  if (!consumerId) {
    return null;
  }
  return CryptoJS.AES.decrypt(consumerId, 'pixclickForms').toString(CryptoJS.enc.Utf8);
};
    export const getemailId = () => {
  const emailId = localStorage.getItem('emailId');
  console.log('Encrypted emailId from localStorage:', emailId);
 
  if (emailId) {
    try {
      const decryptedEmailId = CryptoJS.AES.decrypt(emailId, 'pixclickForms').toString(CryptoJS.enc.Utf8);
      console.log('Decrypted emailId:', decryptedEmailId);
      return decryptedEmailId;
    } catch (error) {
      console.error('Error decrypting emailId:', error);
      return null; // or handle the error accordingly
    }
  } else {
    console.log('No emailId found in localStorage.');
    return null; // or any default value if emailId is not found
  }
};
 
 
 
export const clearStorage = () => {
   localStorage.clear()
};
 