import { useState } from "react";
import "./Header.css";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isResponsive, setResponsive] = useState(false);

  const toggleResponsive = () => {
    setResponsive(!isResponsive);
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <div className="logo-container">
            <a class="navbar-brand" href="/">
              <img
                src="https://pixalivegroup.com/assets/images/logo-light.svg"
                alt=""
                className="img-fluid"
              />
            </a>
          </div>

          <button
            className="navbar-toggler mt-3 "
            type="button"
            onClick={toggleResponsive}
            id="toggle_button"
          >
            {isResponsive ? <FaTimes /> : <FaBars />}
          </button>
          <div
            className={`collapse navbar-collapse  ${
              isResponsive ? "show" : ""
            }`}
          >
            <ul class="navbar-nav mx-auto gap-3 ">
              <li class="nav-item mt-3">
                <p className="fw-bolder fs-5  ">Only For Vendor</p>
              </li>
              <li class="nav-item  ">
                <a
                  href="https://wa.me/7760871042"
                  className="text-decoration-none text-black fw-bold"
                  target="_blank"
                >
                  <p className="fw-bold fs-5 mt-3">7760-871-042</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
