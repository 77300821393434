import React, { useState } from "react";
import "./Contact.css";
import { pixaliveGroupAPI } from "../../Api_pixalivegroup_campagion/Contact";
import { isValidPhone, isValidEmail } from "../../Utils/Validation";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const Contact = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialState = {
    name: "",
    email: "",
    mobileNumber: "",
    location: "",
  };

  const initialStateErrors = {
    name: { required: false },
    email: { required: false },
    mobileNumber: { required: false, valid: false },
    location: { required: false },
  };

  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.name === "") {
      error.name.required = true;
    }
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.location === "") {
      error.location.required = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }

    return error;
  };

  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setErrors(newError);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);

    if (handleErrors(newError)) {
      pixaliveGroupAPI(inputs)
        .then((res) => {
          if (res?.data?.message === "Saved Successfully") {
            toast.success(res?.data?.message);
            e.target.reset();
            handleOpen(); // Show the modal on successful submission
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.res?.data?.message);
        });
    }
  };

  // location suggestion:
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    inputs.location = event.target.value;
    // You can handle the selected location further here
    console.log("Selected Location:", event.target.value);
  };

  const locations = [
    "Delhi",
    "Mumbai",
    "Bengaluru",
    "Pondicherry",
    "Chennai",
    "Kolkata",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Jaipur",
    "Surat",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Visakhapatnam",
    "Bhopal",
    "Patna",
    "Ludhiana",
    "Agra",
    "Nashik",
    "Vadodara",
    "Faridabad",
    "Ghaziabad",
    "Rajkot",
    "Amritsar",
    "Allahabad",
    "Vijayawada",
    "Gwalior",
    "Jodhpur",
    "Madurai",
    "Coimbatore",
    "Tirupur",
  ];

  return (
    <>
      <div className="contactForm mt-4 pt-5">
        <div className="contactFormsDetials p-2">
          <div className="FormDetails ">
            <h4>
              <a href="tel:+91 7760871042">+91 7760871042</a>
            </h4>
            <h4 className="fw-bold mt-5">Official Email</h4>
            <p className="emailpixalive">
              <a href="mailto:contact@pixalive.me">contact@pixalive.me</a>
            </p>
            <h4 className="fw-bold  mt-5">Location</h4>
            <p className="mt-2">
              Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase,
              <br /> Electronic City, Karnataka 560100
            </p>
            <span className="iconsContact">
              <a href="https://www.facebook.com/pixalive.app.9/">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="https://twitter.com/pixaliveo?lang=en">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="https://in.linkedin.com/company/pixalive">
                <i className="bi bi-linkedin"></i>
              </a>
            </span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="messageBox">
              <h2 className="text-center fw-bold">Call out to vendors</h2>
              <h4 className="text-center fw-bold">
                Please fill the form for contact details
              </h4>

              <div className="mt-5">
                <p className="fw-bold ">Full Name</p>
                <input
                  type="text"
                  className="custom_input "
                  placeholder="Enter your name"
                  name="name"
                  onChange={handleInputs}
                  required
                />
                {errors.name.required ? (
                  <p className="error text-danger">Name is required</p>
                ) : null}
              </div>

              <div className="">
                <p className="fw-bold mt-3">Email</p>
                <input
                  type="email"
                  className="custom_input "
                  placeholder="@gmail.com"
                  name="email"
                  onChange={handleInputs}
                  required
                />
                {errors.email.required ? (
                  <p className="error text-danger">Email is required</p>
                ) : errors.email.valid ? (
                  <p className="error">Email is not valid</p>
                ) : null}
                <p className="fw-bold mt-3">Contact No</p>
                <input
                  type="tel"
                  className="custom_input "
                  placeholder="+91"
                  name="mobileNumber"
                  onChange={handleInputs}
                  required
                />
                {errors.mobileNumber.required ? (
                  <p className="error text-danger">Mobile Number is required</p>
                ) : errors.mobileNumber.valid ? (
                  <p className="error">Mobile Number is not valid</p>
                ) : null}
                <p className="mt-3 fw-bold">Location</p>
                <select
                  name="location"
                  id="location"
                  className="custom_input"
                  value={inputs?.location}
                  onChange={handleLocationChange}
                  required
                >
                  <option value="" disabled>
                    Select your location
                  </option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                {errors.location.required ? (
                  <p className="error text-danger">Location is required</p>
                ) : null}
              </div>

              <button
                type="submit"
                className="buttoncolorcss1 shake-animation  mt-4"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography id="transition-modal-description">
                <p className="text-center">
                  <i className="bi bi-hand-thumbs-up"></i>
                </p>
                Thanks for contacting us, we will reach you out soon
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default Contact;
