import React from "react";
import performance from "../../../Assets/performance.png";
import traning from "../../../Assets/business.png";
import weekdays from "../../../Assets/schedule.png";
import certificate from "../../../Assets/certified.png";
import Intrew from "../../../Assets/business (1).png"
import profile from "../../../Assets/profile.png";
import lowcost from "../../../Assets/discount.png";
import realtime from "../../../Assets/augmented-reality.png";
import team from "../../../Assets/team.png";
import job from "../../../Assets/portfolio.png";

const Carrers1 = () => {
  const carreerDetails = [
    {
      id: 1,
      Image: traning,
      content: "Industrial expert session ",
    },
    {
      id: 2,
      Image: certificate,
      content: "Corporate Certificate",
    },
    {
      id: 3,
      Image: weekdays,
      content: "Days Training ",
    },
    {
      id: 4,
      Image: Intrew,
      content: "Interview Preparation",
    },
    {
      id: 5,
      Image: profile,
      content: "Profile Building Session",
    },
    {
      id: 6,
      Image: job,
      content: "Job Guaranty ",
    },
    {
      id: 7,
      Image: lowcost,
      content: "Scholarship Discount ",
    },
    {
      id: 8,
      Image: team,
      content: "Team Work",
    },
    {
      id: 9,
      Image: realtime,
      content: "Live Projects",
    },
    {
      id: 10,
      Image: performance,
      content: "100 plus mock interview ",
    },
  ];
  return (
    <div>
      <div className="Highlights_Card">
        <div className="Card_Heading">
          <h2>Key Highlights</h2>
        </div>
        <div className="Carreer_Card_Data">
          {carreerDetails.map((carreerData) => (
            <div key={carreerData.id} className="Carreer_Cards">
              <div className="Carreer_Image">
                <img src={carreerData.Image} alt="" />
              </div>
              <div className="Career_Content">
                <h4>{carreerData.content}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carrers1;
