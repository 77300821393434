import React, { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
 
import logo from "../../Assets/Labs-logo (2) 3.png";
 
const Header = () => {
 
  const [isOpen, setIsOpen] = useState(false);
 
  const toggleSidebar = (e) => {
    // Check if the click was on the close icon
    if (e.target.classList.contains("close_icon")) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };
   
 
 
  return (
    <div className="Header_container">
      <div className="Header_inner_container">
        <div className="contact_details">
          <div className="mobile_number">
            <p>
              <i class="bi bi-telephone"></i> +91 87785 84566
            </p>
          </div>
          <div className="mail_id">
            <p>
              <i class="bi bi-envelope"></i> contact@pixalive.me
            </p>
          </div>
        </div>
        <div className="social_icons">
       <a href="https://www.facebook.com/profile.php?id=61559897502572"><i class="bi bi-facebook"></i></a> 
         <a href="https://www.linkedin.com/company/31128662/admin/feed/posts/"><i class="bi bi-linkedin"></i></a> 
        <a href="https://www.youtube.com/watch?v=RuypjhNQ0MI"> <i class="bi bi-youtube"></i></a> 
        </div>
      </div>
      <div className="Header_wrapper">
        <div className="header_image">
       <Link to ='/'> <img src={logo} alt="" /></Link>  
        </div>
        <div className="Header_navbar">
        <ul>
         <Link to='/' style={{ textDecoration: "none" }}> <li><a href="">Home</a></li></Link>
        <Link to ="/program" style={{ textDecoration: "none" }} > <li><a href="">Programs</a></li></Link>
       <Link to ="/carrers" style={{ textDecoration: "none" }}> <li><a href="">Careers</a></li></Link>
         <Link to ="/applynow"style={{ textDecoration: "none" }}> <li><a href="">Apply Now</a></li></Link>
         <Link to = "/contact"style={{ textDecoration: "none" }}> <li><a href="">Contact Us</a></li></Link>
        </ul>
        </div>
         
        <div className="header_sidebar" onClick={toggleSidebar}>
      <i className="bi bi-list"></i>
    </div>
    <div
      className={`sidebar-container ${isOpen? "open" : ""}`}
    >
      <div className="sidebar-content">
      <ul>
         <Link to='/' style={{ textDecoration: "none" }}> <li><a href="">Home</a></li></Link>
        <Link to ="/program" style={{ textDecoration: "none" }} > <li><a href="">Programs</a></li></Link>
       <Link to ="/carrers" style={{ textDecoration: "none" }}> <li><a href="">Careers</a></li></Link>
         <Link to ="/applynow"style={{ textDecoration: "none" }}> <li><a href="">Apply Now</a></li></Link>
         <Link to = "/contact"style={{ textDecoration: "none" }}> <li><a href="">Contact Us</a></li></Link>
        </ul>
      </div>
      <div className="close_icon" onClick={(e) => toggleSidebar(e)}>
        <i className="bi bi-x-lg"></i>
      </div>
    </div>
 
      </div>
    </div>
  );
};
 
export default Header;