import React, { useEffect } from 'react'
import './MeetOurTeam.css'
import office1 from "../../../Assets/0.jpg"
import office2 from "../../../Assets/2.jpg"
import office3 from "../../../Assets/3.jpg"
import office4 from "../../../Assets/5.jpg"
import office5 from "../../../Assets/7.jpg"
import office6 from "../../../Assets/8.jpg"
import office8 from "../../../Assets/9.jpg"
import office7 from "../../../Assets/careers.jpg"
import office9 from "../../../Assets/3.jpg"
import office10 from "../../../Assets/2.jpg"


const MeetOurTeam = () => {

  useEffect(() => {
    const carousel = document.querySelector(".carousel_wrapper");
    const nextBtn = document.querySelector('.next');
    const prevBtn = document.querySelector('.prev');
    const firstCardWidth = carousel.querySelector(".carousel_image").offsetWidth;

    if (nextBtn) {
      nextBtn.addEventListener("click", () => {
        carousel.scrollLeft += firstCardWidth;
      });
    }

    if (prevBtn) {
      prevBtn.addEventListener("click", () => {
        carousel.scrollLeft -= firstCardWidth;
      });
    }

    // let index = 1;
    // const width = firstCardWidth;
    // carousel.style.transform = `translateX(${-(width * index)}px)`;

    // carousel.addEventListener('transitionend', function() {
    //   // Check if we've passed the last image
    //   if (carousel.scrollLeft + firstCardWidth >= carousel.offsetWidth) {
    //     // We've reached the end, so loop back to the start
    //     carousel.style.transition = 'none';
    //     index = 1; // Reset index to start
    //     carousel.style.transform = `translateX(${-(width * index)}px)`;
    //   } else if (carousel.scrollLeft <= 0) {
    //     // We've reached the beginning, so loop back to the end
    //     carousel.style.transition = 'none';
    //     index = firstCardWidth.length - 1; // Set index to last element
    //     carousel.style.transform = `translateX(${-(width * index)}px)`;
    //   }
    // });

  }, []);


  return (
    <div className='meetourteam_container'>
           <div className='meetourteam_title'>
               <div className='line'></div>
               <div className='ourteam_title'>MEET OUR TEAM</div>
               <div className='line'></div>
           </div>
           <div className="meetourteam_heading">
            <h2>Meet our exclusive team working toward a
            common goal</h2>
           </div>

           <div className='carousel_container'>

              <span className='next'>&#10095;</span>
              <span className='prev'>&#10094;</span>

            <div className='carousel_wrapper'>
              <div className='carousel_image'>
              <img src={office1} alt="" id='lastImageDuplicate'/>
                <img src={office2} alt="" />
                <img src={office3} alt="" />
                <img src={office4} alt="" />
                <img src={office5} alt="" />
                <img src={office6}alt="" />
                <img src={office7}alt="" />
                <img src={office8} alt="" />
                <img src={office9} alt="" />
              <img src={office10} alt="" id='firstImageDuplicate' />

              </div>
            </div>
           </div>
    </div>
  )
}

export default MeetOurTeam