import React from "react";
import About from "./AobutUS/About";
import Header from "../../Components/Header/Header";
import WeAre from "./WeAre/WeAre";
import Career from "../Carrer/Career/Career";
import Gudance from "./Gudance/Gudance";
import Footer from "../../Components/Footer/Footer";
import Upadates from "./Uapdates/Upadates";
import TrainingTechStack from "./TrainingTechStack/TrainingTechStack";
import MeetOurTeam from "./MeetOurTeam/MeetOurTeam"
// import Apply from "../../Components/Apply/Apply";
// import Opportunity from "../Opportunity/Opportunity";

import Programs from "../Programs/programs";
import Opportunity from "./Opportunity/Opportunity";
const Home = () => {
  return (
    <div>
       <Header />
       <Opportunity/>
      <About />
      <WeAre />
    <TrainingTechStack/>
      <MeetOurTeam/>
      <Gudance />
      <Upadates />
      <Footer />



      {/* <Opportunity/>
     
      
     
      <WeAre />

      
      <Gudance />
      <Upadates />
      <TrainingTechStack />
      <MeetOurTeam />  
       <Apply/>
      <Footer />
      <Career /> */}
   
      {/* <Career /> 
      <Programs/> */}
    </div>
  );
};

export default Home;
