import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid mt-5 pt-5 backgroundfoot">
      <div className="container mt-5">
        <div className="row ">
          <div className="col-lg-3 col-6">
            <ul>
              <a
                href="https://pixalivegroup.com/#"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">BUSINESSES</li>
              </a>
             
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                {" "}
                <li>SOCIAL MEDIA</li>
              </a>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                {" "}
                <li>SAAS</li>
              </a>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                <li>SERVICES</li>
              </a>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                <li>STARTUPS</li>
              </a>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                <li>SOFTWARE TRAINING</li>
              </a>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                <li>SUSTAINABILITY</li>
              </a>
            </ul>
          </div>

          <div className="col-lg-3 col-6">
            <ul>
              <a
                href="https://pixalivegroup.com/Businesses"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">DIVISONS</li>
              </a>
             
              <a
                href="https://pixaliveplatforms.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE PLATFORMS</li>
              </a>
              <a
                href="https://pixaliveworks.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE WORKS</li>
              </a>
              <a
                href="https://pixaliveservices.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE SERVICES</li>
              </a>
              <a
                href="https://pixaliveventures.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE VENTURES</li>
              </a>
              <a
                href="https://pixalivelabs.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE LABS</li>
              </a>
              <a
                href="https://pixalivefoundation.com/"
                className="text-decoration-none text-white"
              >
                <li>PIXALIVE FOUNDATION</li>
              </a>
            </ul>
          </div>

          <div className="col-lg-2 col-6">
            <ul>
              <a
                href="https://pixalivegroup.com/#"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold">COMMUNITY</li>
              </a>
              
              <a
                href="https://pixalivegroup.com/Sustainability"
                className="text-decoration-none text-white"
              >
                {" "}
                <li>EMPOWERMENT</li>
              </a>
              <a
                href="https://pixalivegroup.com/Sustainability"
                className="text-decoration-none text-white"
              >
                <li>EDUCATION</li>
              </a>
              <a
                href="https://pixalivegroup.com/AboutUs"
                className="text-decoration-none text-white"
              >
                {" "}
                <li>OUR BRANDS</li>
              </a>
              <a
                href="https://pixalivegroup.com/AboutUs"
                className="text-decoration-none text-white"
              >
                {" "}
                <li>OUR TEAM</li>
              </a>
              <a
                href="https://pixalivegroup.com/Investors"
                className="text-decoration-none text-white"
              >
                <li>INVESTORS</li>
              </a>
            </ul>
          </div>

          <div className="col-lg-2 col-6 ">
            <ul>
              <a
                href="https://pixalivegroup.com/AboutUs"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">ABOUT US</li>
              </a>

              <a
                href="https://pixalivegroup.com/Blogs"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">BLOGS</li>
              </a>
              <a
                href="https://pixalivegroup.com/Careers "
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">CAREERS</li>
              </a>
              <a
                href="https://pixalivegroup.com/Blogs"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">PRESS RELEASE</li>
              </a>
              <a
                href="https://pixalivegroup.com/ContactUs"
                className="text-decoration-none text-white"
              >
                <li className="fw-bold ">CONTACT US</li>
              </a>
            </ul>
          </div>

          <div className="col-lg-2 col-6 ">
            <img
              src="https://pixalivegroup.com/assets/images/logo-white.svg"
              alt=""
              className="img-fluid  "
            />
            <div className="mt-3">
              <span className="">
                <a
                  href="https://play.google.com/store/apps/details?id=com.pixalive"
                  className="text-decoration-none text-white"
                >
                  <img
                    src="https://pixalivegroup.com/assets/images/pixalive-white.svg"
                    alt=""
                    className="img-fluid pixaliveicons  mx-1"
                  />
                </a>
              </span>
              <span className="">
                <a
                  href="https://x.com/i/flow/login?redirect_after_login=%2FPixaliveG"
                  className="text-decoration-none text-white"
                >
                  <i class="bi bi-twitter-x fs-5  "></i>
                </a>
              </span>
              <span>
                <a
                  href="https://www.youtube.com/@PixaliveGroup"
                  className="text-decoration-none text-white"
                >
                  <i class="bi bi-youtube fs-5 mx-3"></i>
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/404/"
                  className="text-decoration-none text-white"
                >
                  <i class="bi bi-linkedin fs-5"></i>
                </a>
              </span>
            </div>
          </div>
          <div className="mt-5">
            <hr />
            <div className="text-center small ">
              <p>Copyright © 2023 Pixalive Group.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
