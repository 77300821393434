import React,{useEffect} from 'react'
import "./Opportunity.css"
import image1 from "../../../Assets/home image 1.jpg"
import image2 from "../../../Assets/home  image.jpg"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Aos from "aos";
 
import Slider from "react-slick";
 
 
const  Opportunity = () => {
  useEffect(() => {
      Aos.init({ duration: 3000 });
    }, []);
  var settings = {
      dots: true,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
  };
 
return (
  <div>    
           
 
    <div className='front-container'>
 
<div className='font-image-container'>
 
<Slider {...settings}>
      <div className='front-image ' data-aos='fade-up'>
          <img src={image1} alt=''/>
       
          <div className='pixal-content ' data-aos='fade-up' >
              <h4 data-aos='fade-up'><i class="bi bi-dash-lg" data-aos='fade-up'></i>Pixalive Labs<i class="bi bi-dash-lg"></i></h4>
              <h2>Empowering Innovation, Fueling Success: Pixalive Labs <br/>for Tomorrow's Workforce</h2>
              <p>We focus on corporate training and skill development, providing a range of technology programs that help people get better at their jobs<br/> and prepare for shifts in the business world.</p>
          <Link to ='/applynow'> <button data-aos='fade-left'>APPLY NOW<i class="bi bi-arrow-right"></i></button></Link> 
         
          </div>
         
      </div>
      <div className='front-image  '>
          <img src={image2} alt=''/>
       
          <div className='pixal-content' data-aos='fade-up'>
              <h4 data-aos='fade-up'><i class="bi bi-dash-lg" data-aos='fade-up'></i>Pixalive Group's Grand Opportunity<i class="bi bi-dash-lg"></i></h4>
              <h2>Launching Your Career: 1000 Freshers Wanted atPixalive Group via Pixalive Labs</h2>
              <p>Join Pixalive Labs' Innovative Training Program and Launch Your Career with Pixalive Group!</p>
         <Link to = '/applynow'><button data-aos='fade-left'>APPLY NOW<i class="bi bi-arrow-right"></i></button></Link> 
         
          </div>
         
      </div>
</Slider>
      </div>
 
    </div>
   
 
 
 
  </div>
)
}
 
 
export default Opportunity;